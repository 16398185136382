import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private storage: StorageService,
    private messageService: MessageService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user: any) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', 'null');
      }
    });
  }

  // Sign in with email/password
  async SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user)
        this.ngZone.run(() => {
          this.router.navigate(['a/dashboard']);
        });
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['ua/verify-email-address']);
      });
  }
  // Reset Forggot password
  async ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.messageService.add({ icon: 'fa fa-mail ', severity: 'info', summary: 'Password reset email sent', detail: `Check your inbox` });
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }
  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider())
  }
  async AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result.user) // SOURCE OF ERROR ON LOGIN We really only should be running the redirect only when it has set the userData.then(() => { });
        this.ngZone.run(() => {
          localStorage.setItem('user', JSON.stringify(result.user));
          this.router.navigate(['a/dashboard']);
        });
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    // Fetch existing user data from Firestore
    userRef.get().subscribe((doc) => {
      let userData: any;

      if (doc.exists) {
        // If user data exists, retain existing photoURL and displayName
        const existingData = doc.data();
        userData = {
          uid: user.uid,
          email: user.email,
          displayName: existingData?.displayName || user.displayName,
          photoURL: existingData?.photoURL || user.photoURL,
          emailVerified: user.emailVerified,
          sharedExperiments: existingData?.sharedExperiments || {},
          tutorial: existingData?.tutorial ?? true,
          variables: existingData?.variables || {
            plant: {
              timeSeries: {},
              metadata: {}
            },
            env: {
              timeSeries: {},
              metadata: {}
            }
          }
        };
      } else {
        // If no existing user data, use the data from the user object
        userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          sharedExperiments: {},
          tutorial: true,
          variables: {
            plant: {
              timeSeries: {},
              metadata: {}
            },
            env: {
              timeSeries: {},
              metadata: {}
            }
          }
        };
      }

      // Store the user data immediately to local storage for real-time use
      localStorage.setItem('user', JSON.stringify(userData)); // Synchronize local storage with Firestore data
      console.log('User data set in local storage:', JSON.parse(localStorage.getItem('user')!));

      // Create object in rate limits if it doesn't exist already
      this.afs.collection("user_billing").doc(user.uid).get().subscribe((doc) => {
        if (!doc.exists) {
          this.afs.collection("user_billing").doc(user.uid).set({
            requestCount: 0,
            lastRequestMonth: new Date().getMonth(),
            lastRequestYear: new Date().getFullYear(),
            monthlyLimit: 5
          });
        }
      });

      return userRef.set(userData, {
        merge: true,  // Merge with existing data
      });
    });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.storage.clear();
      localStorage.removeItem('user'); // or use localStorage.clear() to remove all entries
      this.router.navigate(['ua/sign-in']);
    });
  }
}