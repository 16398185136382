import { Injectable } from '@angular/core';
import { User } from '../@model/user';

/**
 * Used to read and write to disk
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  convertSize(sizeBytes: number | undefined): string | undefined {
    if (sizeBytes === 0) {
      return "0B";
    }
    if (sizeBytes) {
      const sizeNames = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(sizeBytes) / Math.log(1024));
      const p = Math.pow(1024, i);
      const s = Math.round(sizeBytes / p * 100) / 100;  // Round to two decimal places

      return `${s} ${sizeNames[i]}`;
    } else {
      return undefined
    }
  }


  /**
   * Will store user.
   * @param user
   */
  user(user: User) {
    localStorage.setItem('_u', JSON.stringify(user));
  }

  getUser(): User | null {
    const storage = localStorage.getItem('_u');
    if (storage) {
      return JSON.parse(storage);
    }
    return null;
  }

  /**
   * Will store token.
   * @param token
   */
  token(token: any) {
    localStorage.setItem('_t', token);
  }

  getToken() {
    return localStorage.getItem('_t');
  }

  /**
   * Will delete the entire storage for the webapp.
   * Should be used on logout.
   */
  clear() {
    localStorage.clear();
  }

  /**
   * Not in use
   * @returns The token
   */
  refreshToken() {
    return 'none';
  }

  constructor() { }
}
